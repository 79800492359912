//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { LookupService } from '@/services/lookup.service';
import { DoctorService } from '@/services/doctor.service';
import { PharmacyService } from '@/services/pharmacy.service';
export default {
  props: {
    rowData: {
      type: Object,

      default() {
        return {};
      }

    }
  },

  data() {
    return {
      showDialog: false,
      buttonDisabled: false,
      alert: {
        show: false,
        message: ''
      },
      shippingaddress: {
        address1: '',
        address2: '',
        city: '',
        state: '',
        zipcode: ''
      },
      sameaddress: true,
      billingaddress: {
        address1: '',
        address2: '',
        city: '',
        state: '',
        zipcode: ''
      },
      supportedStates: [],
      selectedState: '',
      doctorsInState: [],
      selectedDoctor: '',
      isFetching: false,
      newPharmacyName: '',
      isDoctorInState: false,
      transferNotes: ''
    };
  },

  watch: {
    async showDialog(show) {
      if (show) {
        const {
          data
        } = await LookupService.getStates();
        this.supportedStates = data.filter(state => state.supported && this.rowData.shippingaddress.state !== state.id).map(state => ({
          label: state.name,
          code: state.id,
          pharmacyid: state.pharmacyid
        }));
      } else {
        this.shippingaddress = {
          address1: '',
          address2: '',
          city: '',
          state: '',
          zipcode: ''
        };
        this.billingaddress = {
          address1: '',
          address2: '',
          city: '',
          state: '',
          zipcode: ''
        };
        this.sameaddress = true;
        this.supportedStates = [];
        this.selectedState = '';
        this.doctorsInState = [];
        this.selectedDoctor = '';
        this.isFetching = false;
        this.newPharmacyName = '';
      }
    },

    async selectedState(val) {
      if (val) {
        var _this$rowData$doctorc;

        this.isFetching = true;
        this.newPharmacyName = '';
        this.selectedDoctor = '';
        this.shippingaddress.state = val.code;
        const {
          data
        } = await DoctorService.getDoctorsByState(val.code);
        this.doctorsInState = data.map(doctor => ({
          label: `Dr. ${doctor.firstname} ${doctor.lastname}`,
          code: doctor.id
        }));
        this.isDoctorInState = (_this$rowData$doctorc = this.rowData.doctorcoveredstate) === null || _this$rowData$doctorc === void 0 ? void 0 : _this$rowData$doctorc.some(({
          state
        }) => state === val.code);

        if (val.pharmacyid) {
          const pharmacyResponse = await PharmacyService.getPharmacy(val.pharmacyid);
          this.newPharmacyName = pharmacyResponse.data.StoreName;
        }

        const foundPriorityDoctor = this.doctorsInState.find(({
          code
        }) => code === process.env.VUE_APP_PRIORITY_DOCTOR);

        if (foundPriorityDoctor) {
          this.selectedDoctor = foundPriorityDoctor;
        }

        this.isFetching = false;
      }
    }

  },
  methods: {
    showAlert(message) {
      this.alert.message = message;
      this.alert.show = true;
    },

    async submitChanges() {
      this.alert.show = false;

      try {
        var _this$selectedDoctor, _this$selectedState;

        if (!this.transferNotes) {
          return this.showAlert('Kindly fill up all the fields');
        }

        if (this.rowData.acceptedbydoctorid && !this.isDoctorInState && !((_this$selectedDoctor = this.selectedDoctor) !== null && _this$selectedDoctor !== void 0 && _this$selectedDoctor.code && (_this$selectedState = this.selectedState) !== null && _this$selectedState !== void 0 && _this$selectedState.code)) {
          this.showAlert('Kindly fill up all the fields');
          return;
        }

        this.buttonDisabled = true;
        const transferStateData = {
          shipping: {
            address1: this.shippingaddress.address1,
            ...(this.shippingaddress.address2 && {
              address2: this.shippingaddress.address2
            }),
            state: this.shippingaddress.state,
            zipcode: this.shippingaddress.zipcode.toString(),
            country: 'US',
            city: this.shippingaddress.city
          },
          notes: this.transferNotes
        };

        if (this.rowData.acceptedbydoctorid && !this.isDoctorInState) {
          transferStateData.doctorid = this.selectedDoctor.code;
        }

        if (this.sameaddress) {
          transferStateData.sameaddress = true;
        } else {
          transferStateData.billing = {
            address1: this.billingaddress.address1,
            address2: this.billingaddress.address2,
            zipcode: this.billingaddress.zipcode.toString(),
            country: 'US',
            state: this.billingaddress.state,
            city: this.billingaddress.city
          };
        }

        await DoctorService.patientTransferState(this.rowData.id, transferStateData);
        this.$emit('refresh-self');
        this.showDialog = false;
      } catch (err) {
        this.showAlert(err.data.message || 'Internal server error');
      } finally {
        this.transferNotes = '';
        this.buttonDisabled = false;
      }
    }

  }
};